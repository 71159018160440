import { Box, Typography } from '@mui/material'
import gsap, { Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect } from 'react'
import './Intro.scss'

const Intro = ({timeline}) => {
    
    useEffect(()=>{
        console.clear();

        gsap.registerPlugin(ScrollTrigger);
        
       
        // on Landing page
        timeline.from('.slide_img', 1.2, {
            y: 100,
            ease: Power3.easeOut,
            opacity: 0
        },"-=0.75")//overlaps by 0.75 seconds
        .from('.slide_description', 1,{
            opacity: 0,
            y: "-200",
            skewY: 10,
            // stagger: {
            //     amount: .4
            // }
        },"-=1")
        // for scroll

        const panels = gsap.utils.toArray(".slide");
        // const content = gsap.utils.toArray(".contento");
        
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".intro_section",
            start: "top top",
            // end:'bottom bottom',
            end: () => "+=" + 100 * panels.length + "%",
            pin: true,
            scrub: true,
            // markers: true
          }
        });
        
        panels.forEach((panel, index) => {
          tl.from(
            panel,
            {
              yPercent: 100,
              ease: "none"
            },
            "+=0.25"
          );
          
        });
        // navbar background color change
        gsap.to('.rpsingh_navBar',1,{
            scrollTrigger:{
              trigger:panels[0],
              start:'top top',
              toggleActions:'play none none reverse'
            },
            background:'#203444',
            ease:'none'
          })
        
    })
  return (
    <Box className='intro_section' id='intro_section'>
        <Box class="slides slide-flex" >
            <Box class="first">
                <Box className='slide_description'>
                    <Box className='slide_description_content'>
                      <Typography className='description_header'>HEY, I'M 
                      <br/>Ram Pratap Singh</Typography>
                      <Typography className='description_body'>
                        {/* Hi, I am Ram Pratap Singh. I'm FullStack website Developer based in Bihar,India. */}
                        A Fulltack Web Developer building the Frontend of Websites and Rest API/Web Applications that leads to the success of the overall product
                        </Typography>
                    </Box>
                </Box>
                <Box className='slide_img'>
                    <img src='/images/background1-PhotoRoom1.png' alt='rpsingh_img'/>
                </Box>
            </Box>
            <Box class="slide second">
                <Box className='slide_description' >
                  <Box className='slide_description_content2'>
                    <Typography className='description_header others'>Designer & FullStack Developer</Typography>
                    <Typography className='description_body'>Hi, I am focusing on creating attractive and responsive websites with the help of experiences i have.</Typography>
                  </Box>
                </Box>
                <Box className='slide_img'>
                    {/* <img src='/images/fullstack_developer.png' alt='fullstack_img'/> */}
                    <img src='/images/full_stack.png' alt='fullstack_img'/>
                </Box>
            </Box>
            <Box class="slide third">
                <Box className='slide_description'>
                  <Box className='slide_description_content3'>
                    <Typography className='description_header  others' sx={{color:'#ffffff'}}>Mentor & Lecturor</Typography>
                    <Typography className='description_body' color={'#000000'}>Hi, I'm trying to teach student's of economically and socialy backward section, with simple and easy description of reactjs,nodejs and springboot, core php.</Typography>
                  </Box>
                </Box>
                <Box className='slide_img'>
                    <img src='/images/mentor1.png' alt='mentor_img'/>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default Intro
