import React from 'react'
import { Box, Typography } from '@mui/material'
import NavBar from '../components/topBar/NavBar'
// import Intro from '../components/Intro/Intro'
import '../customStyling/Home.scss'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import Footer from '../components/footer/Footer'
import Intro from '../components/Intro/Intro'
import MyWork from '../components/myWork/MyWork'
import MyExpertise from '../components/myExpertise/MyExpertise'
import ProfessionalExperience from '../components/experience/ProfessionalExperience'
import AboutMyself from '../components/about/AboutMyself'

const Dashboard = () => {
  gsap.registerPlugin(ScrollTrigger);
  let tl = gsap.timeline();
  return (
    <Box className='rpsingh_home '>
      <NavBar timeline={tl} />
      <Intro timeline={tl} />
      <MyExpertise/>
      <MyWork/>
      <ProfessionalExperience/>
      <AboutMyself/>
      <Footer/>
    </Box>
  )
}

export default Dashboard
