import React from 'react'
import './App.css'
import Dashboard from './pages/Dashboard';
import { Route, Routes } from 'react-router-dom';

const App = () => {
	
	return (
 
      
	<Routes>
      <Route path='/'>
        <Route index element={<Dashboard/>} />
      </Route>
    </Routes>
	)
}

export default App;
