import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import './MyExpertise.scss'

const MyExpertise = () => {
  return (
    <Box className='expertise'>
      <Box className='expertise_header'>
        <Typography variant='h2'>My Expertise</Typography>
      </Box>
      <Divider/>
      <Box className='expertise_body'>
        <Box className='expertise_section_left'>
            <Box className='expertise_section_head'>
                <img src='/images/react_logo.png'/>
                <Typography className='expertise_label'>Frontend Dev<br/>React, NextJS</Typography>
            </Box>
            <Box className='expertise_section_desc'>
                <Typography className='desc_text'>Passionate about UI/UX. Over 4 years of development experience in HTML, CSS, JS, React and NextJS frameworks.</Typography>
            </Box>
        </Box>
        <Box className='expertise_section_middile'>
            <Box className='expertise_section_head'>
            <img src='/images/rest_api.png'/>
                <Typography className='expertise_label'> RESTful API<br/>web API</Typography>
            </Box>
            <Box className='expertise_section_desc'>
                <Typography className='desc_text'>Skilled in developing RESTful API/ web API using Php, Node.js and Spring Boot .</Typography>
            </Box>
        </Box>
        <Box className='expertise_section_right'>
            <Box className='expertise_section_head'>
                <img src='/images/database_logo.png'/>
                <Typography className='expertise_label'> Database<br/>Management</Typography>
            </Box>
            <Box className='expertise_section_desc'>
                <Typography className='desc_text'>Experienced in managing Databases like MySQL ,MongoDB & Relational database.</Typography>
            </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MyExpertise
