import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './MyWork.scss'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'

const MyWork = () => {
    
    useEffect(()=>{
        console.clear();

        gsap.registerPlugin(ScrollTrigger);
        
        
        const content = gsap.utils.toArray(".contents");
        gsap.set(".contents", { zIndex: (i, target, targets) => targets.length - i });

        // const content_animation = gsap.to('.contents:not(:last-child)',{
        //     clipPath:()=>{
        //         return 'inset(0px 0px 100% 0px)'
        //     },
        //     stagger:.5,
        //     ease:'none' 
        // });
        

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.portfolio',
                start: "top top",
                // end:'bottom bottom',
                end: () => "+=" + 100 * content.length + "%",
                pin: true,
                scrub: 1,
                // markers: true
              }
        });
        content.forEach((panel, i) => {
        //     ScrollTrigger.create({
        //     trigger:'.portfolio',
        //     start:'top top ',
        //     end: () => "+=" + 100 * content.length + "%",
        //     scrub:1
        // })
        if (i) {
            tl.to(panel, { autoAlpha: 1, y: "0%" });
          }
          if (content[i + 1]) {
            tl.to(panel, { autoAlpha: 0, y: "0%" });
          }
            
        });

//  gallery related
        const galleryPanel = gsap.utils.toArray(".photo");
        galleryPanel.map((item,index)=>{
            item.style.zIndex = galleryPanel.length - index;
        });

        gsap.set('.photo',{
            clipPath:()=>{
                return 'inset(0px 0px 0px 0px)'
            }
        });

        const animation = gsap.to('.photo:not(:last-child)',{
            clipPath:()=>{
                return 'inset(0px 0px 100% 0px)'
            },
            stagger:.5,
            ease:'none' 
        });

        ScrollTrigger.create({
            trigger:'.portfolio',
            start:'top top ',
            // end:'bottom bottom',
            end: () => "+=" + 100 * content.length + "%",
            animation:animation,
            scrub:1
        });
        // mywork topic related
        gsap.to('.section-name',{
            scrollTrigger:{
                trigger:'.portfolio',
                start:'top +=10',
                toggleActions:'play none none reverse'
            },
            yPercent:25
        })
    },[])
  return (
    <Box className='portfolio' id='portfolio'>
      <Box className='section-name'>
        <Typography variant='h2'> My Work</Typography>
      </Box>
      <Divider/>
      <Box className='portfolio_sections'>
        <Box className='description_panel flex-portfolio'>
            <Box className='contents pms'>
                <Typography variant='h5' className='contents_label_text'>SSK PMS : GST Billing & Accounting Application</Typography>
                <Typography className='contents_desc_text'>SSK PMS is a technology-driven company delivering Billing, Payment & Delivery Solutions for Small & Medium Businesses</Typography>
                <Box className='contents_button'>
                    <Button variant='outlined' color='inherit'>view More</Button> 
                </Box> 
            </Box>
            <Box className='contents ledger-book'>
                <Typography variant='h5' className='contents_label_text'>Ledger Book : Best Digital Bahi Khata & Ledger Application</Typography>
                <Typography className='contents_desc_text'>OkCredit is a free digital Udhar-Khata app for businesses & shop owners. Manage your ledger account book digitally in English language.</Typography>
                <Box className='contents_button'>
                    <Button variant='outlined' color='inherit'>view More</Button> 
                </Box> 
            </Box> 
            <Box className='contents online-billing'>
                <Typography variant='h5' className='contents_label_text'>SSK E-Invoice : free Online Billing and Invoicing Application</Typography>
                <Typography className='contents_desc_text'>SSK E-Invoice is an online invoicing software designed to help small businesses with invoicing and payment collection. It is simple, secure, and absolutely free</Typography>
                <Box className='contents_button'>
                    <Button variant='outlined' color='inherit'>view More</Button> 
                </Box>
            </Box>       
        </Box>
        <Box className='gallery_panel flex-portfolio '>
            <Box className='photo ssk-pms'>
                <img src='/images/portfolio_pms_img1.png' alt='billing_img'/>
            </Box>
            <Box className='photo ssk-ledger'>
                <img src='/images/portfolio_ledgerbook.png' alt='ledgerbook_img'/>
            </Box>
            <Box className='photo ssk-billing'>
                <img src='/images/portfolio_billing_img.png' alt='billing_img'/>
            </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MyWork
