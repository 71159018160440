import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import './Footer.scss'
import { Dialpad, Email, Facebook, Instagram, Phone, WhatsApp } from '@mui/icons-material'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const scrollTo = (id)=>{
        var element = document.getElementById(id);
        element.scrollIntoView({behavior:"smooth"});
    }
    const downloadFile = () => {
        window.location.href = "/documents/ram_resume_2023.docx"
    }
    const navigate =useNavigate();
  return (
    <Box className='rpsingh_footer' id='rpsingh_footer'>
      {/* <Box className='manage_navbar_space'>

      </Box> */}
      <Box className='footer_contents'>
        <Box className='footer_description'>
            <Typography variant='h4'>I’m a fullstack Web Developer, based in Kochas,Bihar. Currently working as freelancer for SSk Infotech.
             {/* You can see my hobbies on My Social Media Platforms and some photos on me too. */}
            </Typography>
        </Box>
        <Box className='footer_contact'>
            <Box className='contact_left'>
                <Typography className='label'>Drop Message</Typography>
                <Box className='contact_form' component={'form'}>
                    <TextField
                        fullWidth
                        size='small'
                        variant='filled'
                        label='Your Name'
                        id='name'
                        name='name'
                        // value=''
                        // onChange=''

                    />
                    <TextField
                        fullWidth
                        size='small'
                        variant='filled'
                        label='Your Email'
                        id='email'
                        name='email'
                        // value=''
                        // onChange=''

                    />
                    <TextField
                        fullWidth
                        size='small'
                        variant='filled'
                        label='Contact Number'
                        id='contact_number'
                        name='contact_number'
                        // value=''
                        // onChange=''

                    />
                    <TextField
                        fullWidth
                        size='small'
                        variant='filled'
                        label='Subject'
                        id='subject'
                        name='subject'
                        // value=''
                        // onChange=''

                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        size='small'
                        variant='filled'
                        label='Description'
                        id='description'
                        name='description'
                        // value=''
                        // onChange=''

                    />
                    <Box className='submit_button'>
                        <Button variant='contained' color='inherit'>Send Message</Button>
                    </Box>
                </Box>
            </Box>
            <Box className='contact_right'>
                <Typography className='label'>Contact Me via:</Typography>
                <Box className='contact_details'>
                    <Typography><span><IconButton><address/></IconButton></span>B31-North Patel Nager<br/><span><IconButton><address/></IconButton></span>Kochas,Rohtas<br/><span><IconButton><address/></IconButton></span>Bihar,821112</Typography>
                    <Typography><span><IconButton><Phone/></IconButton></span> +91- 7906397570,8899799109</Typography>
                    <Typography><span><IconButton><Email/></IconButton></span> rpsinghdev@gmail.com</Typography>
                </Box>
                <Box className='download_resume'>
                    <Button variant='contained' color='inherit' onClick={downloadFile}>Download Resume</Button>
                </Box>
                <Box className='social_media_links'>
                    <IconButton onClick={()=>window.open('https://www.facebook.com/profile.php?id=100054814653561')}><Facebook/></IconButton>
                    <IconButton ><Instagram/></IconButton>
                    <IconButton><LinkedInIcon/></IconButton>
                    <IconButton onClick={()=>window.open('https://wa.me/7906397570')}><WhatsApp/></IconButton>
                </Box>
            </Box>
        </Box>
        <Box className='footer_copyright'>
            <Typography>Copyright ©2024 All rights reserved | Created by R.P.Singh</Typography>
        </Box>
      </Box>
      <Box className='move_top'>
        <IconButton onClick={()=>scrollTo('intro_section')}><KeyboardDoubleArrowUpIcon/></IconButton>
      </Box>
    </Box>
  )
}

export default Footer
