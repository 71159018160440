import React from 'react'

const ExperienceList = () => {
    const menu = [
        {
            companyName:'SSK INFOTECH @ FullStack Developer',
            period:'August 2022 - Present',
            location:'Sasaram, Bihar,India',
            link:'sskinfotech.in',
            description:'Developing front-end on React Js  and complete backend panel on SpringBoot and nodejs.',
            keywords:[
                'Javascript',
                'React',
                'Node JS',
                'Redux',
                'SpringBoot'
            ],
            icon:'ssk_icon.png'
        },
        {
            companyName:'Nation Roar Media Group @ FullStack Developer',
            period:'July 2021 - june 2022',
            location:'Patna,Bihar,India',
            link:'nationroar.com',
            description:'Developed complete frontend panel in React js  and node js and php in backened.',
            keywords:[
                'JS',
                'HTML',
                'SCSS',
                'Nodejs',
                'php'
            ],
            icon:'nr_icon.png'
        },
        {
            companyName:'Civil Services Exam Preparation @ Student',
            period:'2017 - 2020',
            location:'Prayagraj,UttarPradesh',
            link:'upsc.gov.in',
            description:'I studied and appeared in UPSC and State Public Service Exams for 3 years.',
            keywords:[
                'UPSC',
                'BPSC',
                'UPPSC',
                'JPSC'
            ],
            icon:'student_icon.png'
        },
        {
            companyName:'Dastak Media @ FullStack Developer',
            period:'July 2016 - Aug 2017',
            location:'Remote',
            link:'http://nationaldastak.com/',
            description:'i worked for national dastak as junior fullstack developer, worked on several projects, using react,php,and springboot.',
            keywords:[
                'Javascript',
                'PHP',
                'HTML',
                'CSS',
            ],
            icon:'nd_icon.jpeg'
        }
    ];
  return menu;
}

export default ExperienceList





